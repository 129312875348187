
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faTelegramPlane, faTiktok } from '@fortawesome/free-brands-svg-icons';
import './content.css';

function Content() {
  return (
    <section className="content">
      <h1 style={{ fontWeight: 'bold'}}>Бажаю здоров'я друже!</h1>
      <h1 style={{ fontWeight: 'bold'}}>Наш сайт ще готується до зустрічі з тобою :)</h1>
      <p>А поки можеш знайти нас у наших соц мережах</p>
      <div className="social-buttons-container">
  <div className="social-button" onClick={() => window.open('https://www.instagram.com/arm.drones/', '_blank')}>
    <FontAwesomeIcon icon={faInstagram} href='https://www.instagram.com/arm.drones/' />
  </div>
  <div className="social-button" onClick={() => window.open('https://t.me/khmdrones', '_blank')}>
    <FontAwesomeIcon icon={faTelegramPlane} href='https://t.me/@khmdrones'/>
  </div>
  <div className="social-button" onClick={() => window.open('https://www.facebook.com/arm.drones', '_blank')}>
    <FontAwesomeIcon icon={faFacebookF} style={{width: '16px'}} href='https://www.facebook.com/arm.drones'/>
  </div>
  <div className="social-button" onClick={() => window.open('https://www.tiktok.com/@armdrones', '_blank')}>
    <FontAwesomeIcon icon={faTiktok} style={{width: '20px'}} href='https://www.tiktok.com/@armdrones'/>
  </div>
</div>
      <p>Якщо хочеш потрапити до нас на <b>інженерний курс по FPV у м.</b> Хмельницькому,</p>
      <p>будь ласка заповни заявку↓</p>
      <div className='w-btns-group'>
      <button className="btn btn-primary btn-course" onClick={() => window.open('https://forms.gle/mdHKioDCr3XSBt1z8', '_blank')}>Заявка на курс</button>
      </div>
    </section>
  );
}


export default Content;


