import React from 'react';
import Header from './components/Header/Header';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';
import BackgroundVideo from './components/BackgroundVideo/BackgroundVideo';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="app">
      <BackgroundVideo />
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default App;

