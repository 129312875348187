import React, { useEffect, useRef } from 'react';
import videoSource from '../../assets/DSC_2398.mp4';
import './backgroundVideo.css';

function BackgroundVideo() {
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current && !videoRef.current.playing) {
        try {
          await videoRef.current.play();
        } catch (e) {
          console.error("Error trying to play the video:", e);
        }
      }
    };

    playVideo();
  }, []);

  return (
    <div className="video-container">
      <video
        autoPlay
        loop
        muted
        playsInline
        ref={videoRef}
        className="background-video"
      >
        <source src={videoSource} type="video/mp4" />
      </video>
      <div className="video-overlay"></div>
    </div>
  );
}

export default BackgroundVideo;
