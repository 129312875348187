import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer className='footer'>
      <div className='cotainer'>
        <h2>Контакти:</h2>
        <span style={{color: '#676767'}}>
        Поки наш сайт у розробці, ти завжди можеш зателефонувати або написати нам у WhatsApp, ми обов'язково відповімо.
        </span>
        <p><span style={{color: '#676767'}}>Телефон: </span><span><a onClick={() => window.open('https://wa.me/380689154775', '_blank')}>+380689154775 (WhatsApp)</a></span></p>
      </div>
    </footer>
  );
}

export default Footer;