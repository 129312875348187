import React from 'react';
import Logo from '../../logo.svg';
import './header.css'

function Header() {
    return (
       <header className="header">
       <img src={Logo} alt="Logo" className="App-logo" />
     </header>
    );
  }

export default Header;